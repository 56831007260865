import BookIcon from '@/assets/img/mobile/book.png'
import ClueIcon from '@/assets/img/mobile/clue.png'
import DepositIcon from '@/assets/img/mobile/deposit.png'
import OrderIcon from '@/assets/img/mobile/order.png'
import OtherIcon from '@/assets/img/mobile/other.png'
import PrepaymentIcon from '@/assets/img/mobile/prepayment.png'
import ProductIcon from '@/assets/img/mobile/product.png'
import ReceptIcon from '@/assets/img/mobile/recept.png'
import ZxzsIcon from '@/assets/img/mobile/zxzs.png'
import Finance from '@/assets/img/mobile/finance.png'
import { getAuthMenuList } from '@/service/userService'
import { getOrderAppId } from '@/utils/prototypeAPI'
import { MobileRedirectRouteCenterEnmu } from '@/enmu/redirect'
import { hideWebViewTitle } from '@/utils/qingApi'
import {Grid, GridItem, NavBar, Tabbar, TabbarItem, Toast} from 'vant'
import { computed, defineComponent, ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useUserStore } from '@/store/modules/user'
import { operOrder } from '@/utils/navigationSetting'
import { apiManpowerEfficiency } from '@/service/bi'
export default defineComponent({
    name: 'App',
    components: {
        [Grid.name]: Grid,
        [NavBar.name]: NavBar,
        [Tabbar.name]: Tabbar,
        [GridItem.name]: GridItem,
        [TabbarItem.name]: TabbarItem
    },
    beforeRouteEnter: (to:any, form:any) => {
    },
    setup () {
        hideWebViewTitle()
        const route = useRoute()
        const router = useRouter()
        const activeMenu = ref('/')
        const userData = computed(() => useUserStore().getUserInfo)
        const topMenu = [
            {
                serviceId: 'CRM',
                name: '线索录入',
                icon: ClueIcon,
                url: MobileRedirectRouteCenterEnmu.MSTUDENT
            },
            {
                serviceId: 'Order',
                name: '订单',
                icon: OrderIcon,
                url: MobileRedirectRouteCenterEnmu.Order + '/order/list'
            },
            {
                serviceId: 'Order',
                name: '收据',
                icon: ReceptIcon,
                url: MobileRedirectRouteCenterEnmu.Order + '/recept'
            },
            {
                serviceId: 'ZXZS',
                name: '咨询助手',
                icon: ZxzsIcon,
                url: MobileRedirectRouteCenterEnmu.ZXZS
            },
            {
                serviceId: 'CRM',
                name: '财务管理',
                icon: Finance,
                url: MobileRedirectRouteCenterEnmu.MSTUDENT + '/finance/approvalCenter'
            },
            {
                serviceId: 'Order',
                name: '数据统计',
                icon: OrderIcon,
                url: MobileRedirectRouteCenterEnmu.Order + '/statistics'
            }
        ]
        const resourceGroupList = ref([])
        const authMenu = ref<any>([])
        const handlerMenuClick = async (event: any) => {
            if (event.url === '/morder/recept') {
                operOrder('receipt')
            } else if (event.url === '/morder/order/list') {
                operOrder('order')
            } else if (event.url === '/morder/statistics') {
                // 是否有分校权限
                const isSchool = resourceGroupList.value.some((item:any) => item.resourceGroupCode === 'order_statistics_branch_school')
                if (isSchool) {
                    operOrder('statistics', `&url=${await manpowerEfficiency('7')}`)
                    return
                }
                // 是否有校区权限
                const isCampus = resourceGroupList.value.some((item:any) => item.resourceGroupCode === 'order_statistics_campus')
                if (isCampus) {
                    operOrder('statistics', `&url=${await manpowerEfficiency('6')}`)
                    return
                }
                // 是否有咨询师权限
                const isConsultant = resourceGroupList.value.some((item:any) => item.resourceGroupCode === 'order_statistics_consultants')
                if (isConsultant) {
                    operOrder('statistics', `&url=${await manpowerEfficiency('5')}`)
                    return
                }
                Toast('暂无权限')

                //
            } else {
                if (event.url === '/mconsulthelper') {
                    localStorage.setItem('isRefresh', 'isRefresh')
                }
                router.push(event.url + '/')
            }
        }

        // 获取bi地址
        const manpowerEfficiency = async (type:string) => {
            const params = {
                type
            }
            Toast.loading({
                message: '加载中...',
                duration: 0,
                forbidClick: true
            })
            const res = await apiManpowerEfficiency(params)
            Toast.clear()
            return res.result
        }

        const handlerMenuChange = (url: string) => {
            router.push({ path: url })
        }
        const initMenuData = async () => {
            const result = await getAuthMenuList({ appId: 'orderMobile' })
            if (result) {
                topMenu.forEach(el => {
                    if (result.menuList.some((it:any) => it.href === el.url)) {
                        authMenu.value.push(el)
                    }
                })
                resourceGroupList.value = result.resourceGroupList
            }
        }
        onMounted(() => {
            if ((localStorage.getItem('isRefresh'))) {
                localStorage.setItem('isRefresh', '')
                window.location.replace('/')
            }
        })
        initMenuData()
        return {
            authMenu,
            activeMenu,
            handlerMenuClick,
            handlerMenuChange
        }
    }
})
