import { getCORPID, getOrderAppId, getOrderURL } from './prototypeAPI'
import { useUserStore } from '@/store/modules/user'
import * as dd from 'dingtalk-jsapi'
const userData = useUserStore().getUserInfo
export const operOrder = (type = '', params = ''):void => {
    if (useUserStore().getClientType === 'dingding') {
        dd.biz.navigation.navigateToPage({
            url: getOrderURL() + '?corpId=' + getCORPID() + '&orgId=' + (userData ? userData.currentOrgId : '') + '&type=' + type + '&clientType=dingding' + params,
            onSuccess: (res:any) => {
                console.log(JSON.stringify(res))
            },
            onFail: (err:any) => {
                console.log(JSON.stringify(err))
            }
        })
    } else {
        window.qing.call('gotoLightApp', {
            appId: getOrderAppId(),
            appName: '选课',
            urlParam: 'orgId=' + (userData ? userData.currentOrgId : '') + '&type=' + type + '&clientType=yzj'
        })
    }
}
